var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tbl_row" }, [
      _vm._m(0),
      _c("tbody", [
        _vm._m(1),
        _c("tr", [
          _vm._m(2),
          _c(
            "td",
            [
              _c("e-input-number", {
                staticClass: "temp_class",
                attrs: {
                  point: 3,
                  isMinus: true,
                  isComma: true,
                  name: "temp_name",
                  id: "temp_id",
                },
                model: {
                  value: _vm.sample.num1,
                  callback: function ($$v) {
                    _vm.$set(_vm.sample, "num1", $$v)
                  },
                  expression: "sample.num1",
                },
              }),
            ],
            1
          ),
          _c("td", [
            _c("p", [_vm._v("sample.num1")]),
            _c("p", { staticStyle: { color: "blue" } }, [
              _vm._v(_vm._s(_vm.sample.num1)),
            ]),
          ]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sample.tempNum1,
                  expression: "sample.tempNum1",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "text" },
              domProps: { value: _vm.sample.tempNum1 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.sample, "tempNum1", $event.target.value)
                },
              },
            }),
            _vm._v("  "),
            _c(
              "button",
              {
                staticClass: "button gray sm",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.apply("NUM1")
                  },
                },
              },
              [_vm._v("적용")]
            ),
          ]),
        ]),
        _c("tr", [
          _vm._m(3),
          _c(
            "td",
            [
              _c("e-input-number", {
                model: {
                  value: _vm.sample.num2,
                  callback: function ($$v) {
                    _vm.$set(_vm.sample, "num2", $$v)
                  },
                  expression: "sample.num2",
                },
              }),
            ],
            1
          ),
          _c("td", [
            _c("p", [_vm._v("sample.num2")]),
            _c("p", { staticStyle: { color: "blue" } }, [
              _vm._v(_vm._s(_vm.sample.num2)),
            ]),
          ]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sample.tempNum2,
                  expression: "sample.tempNum2",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "text" },
              domProps: { value: _vm.sample.tempNum2 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.sample, "tempNum2", $event.target.value)
                },
              },
            }),
            _vm._v("  "),
            _c(
              "button",
              {
                staticClass: "button gray sm",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.apply("NUM2")
                  },
                },
              },
              [_vm._v("적용")]
            ),
          ]),
        ]),
        _c("tr", [
          _vm._m(4),
          _c(
            "td",
            [
              _c("e-input-number", {
                attrs: { isPhone: true },
                model: {
                  value: _vm.sample.num3,
                  callback: function ($$v) {
                    _vm.$set(_vm.sample, "num3", $$v)
                  },
                  expression: "sample.num3",
                },
              }),
            ],
            1
          ),
          _c("td", [
            _c("p", [_vm._v("sample.num3")]),
            _c("p", { staticStyle: { color: "blue" } }, [
              _vm._v(_vm._s(_vm.sample.num3)),
            ]),
          ]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sample.tempNum3,
                  expression: "sample.tempNum3",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "text" },
              domProps: { value: _vm.sample.tempNum3 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.sample, "tempNum3", $event.target.value)
                },
              },
            }),
            _vm._v("  "),
            _c(
              "button",
              {
                staticClass: "button gray sm",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.apply("NUM3")
                  },
                },
              },
              [_vm._v("적용")]
            ),
          ]),
        ]),
        _c("tr", [
          _vm._m(5),
          _c(
            "td",
            [
              _c("e-input-number", {
                attrs: {
                  name: "temp_name",
                  id: "temp_id",
                  inputClass: "temp_class",
                  readonly: _vm.sample.readonly,
                },
              }),
            ],
            1
          ),
          _c("td", [
            _c("p", [_vm._v("sample.num4")]),
            _c("p", { staticStyle: { color: "blue" } }, [
              _vm._v(_vm._s(_vm.sample.num4)),
            ]),
          ]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sample.tempNum4,
                  expression: "sample.tempNum4",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "text" },
              domProps: { value: _vm.sample.tempNum4 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.sample, "tempNum4", $event.target.value)
                },
              },
            }),
            _vm._v("  "),
            _c(
              "button",
              {
                staticClass: "button gray sm",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.apply("NUM4")
                  },
                },
              },
              [_vm._v("적용")]
            ),
            _c("p", [
              _c(
                "button",
                {
                  staticClass: "button gray sm",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.fnReadonly()
                    },
                  },
                },
                [_vm._v("readonly : " + _vm._s(_vm.sample.readonly))]
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _vm._m(6),
          _c(
            "td",
            [
              _c("e-input-number", {
                attrs: {
                  point: 3,
                  isMinus: true,
                  isComma: true,
                  isReturnNumber: true,
                  name: "temp_name",
                  id: "temp_id",
                  inputClass: "temp_class",
                },
                model: {
                  value: _vm.sample.num5,
                  callback: function ($$v) {
                    _vm.$set(_vm.sample, "num5", $$v)
                  },
                  expression: "sample.num5",
                },
              }),
            ],
            1
          ),
          _c("td", [
            _c("p", [_vm._v("sample.num5")]),
            _c("p", { staticStyle: { color: "blue" } }, [
              _vm._v(_vm._s(_vm.sample.num5)),
            ]),
            _c("p", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(typeof _vm.sample.num5)),
            ]),
          ]),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sample.tempNum5,
                  expression: "sample.tempNum5",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "text" },
              domProps: { value: _vm.sample.tempNum5 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.sample, "tempNum5", $event.target.value)
                },
              },
            }),
            _vm._v("  "),
            _c(
              "button",
              {
                staticClass: "button gray sm",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.apply("NUM5")
                  },
                },
              },
              [_vm._v("적용")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col"),
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "200" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("구분")]),
      _c("th", [_vm._v("Input")]),
      _c("th", [_vm._v("Return 값")]),
      _c("th", [_vm._v("값변경")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", [_vm._v("마이너스(O)")]),
      _c("p", [_vm._v("콤마표시(O)")]),
      _c("p", [_vm._v("소수점(3자리)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", [_vm._v("마이너스(X)")]),
      _c("p", [_vm._v("콤마표시(X)")]),
      _c("p", [_vm._v("소수점(X)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", [_vm._v("전화번호????")]),
      _c("p", [_vm._v("0 부터 시작가능")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("p", [_vm._v("Element Attribute")]),
      _c("p", [_vm._v("(name, id, class, readonly)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("p", [_vm._v("숫자형 으로 리턴")]), _c("p")])
  },
]
render._withStripped = true

export { render, staticRenderFns }