<template>
  <div>
    <table class="tbl_row">
      <colgroup>
        <col>
        <col width="200">
        <col width="200">
        <col width="200">
      </colgroup>
      <tbody>
        <tr>
          <th>구분</th>
          <th>Input</th>
          <th>Return 값</th>
          <th>값변경</th>
        </tr>
        <tr>
          <td>
            <p>마이너스(O)</p>
            <p>콤마표시(O)</p>
            <p>소수점(3자리)</p>
          </td>
          <td>
            <e-input-number :point="3" :isMinus="true" :isComma="true" v-model="sample.num1"
                name="temp_name" id="temp_id" class="temp_class"/>
          </td>
          <td>
            <p>sample.num1</p>
            <p style='color:blue'>{{ sample.num1 }}</p>
          </td>
          <td>
            <input type="text" v-model="sample.tempNum1" style='width:100px'/>&nbsp;
            <button class="button gray sm" @click.prevent="apply('NUM1')">적용</button>
          </td>
        </tr>
        <tr>
          <td>
            <p>마이너스(X)</p>
            <p>콤마표시(X)</p>
            <p>소수점(X)</p>
          </td>
          <td>
            <e-input-number v-model="sample.num2" />
          </td>
          <td>
            <p>sample.num2</p>
            <p style='color:blue'>{{ sample.num2 }}</p>
          </td>
          <td>
            <input type="text" v-model="sample.tempNum2" style='width:100px'/>&nbsp;
            <button class="button gray sm" @click.prevent="apply('NUM2')">적용</button>
          </td>
        </tr>
        <tr>
          <td>
            <p>전화번호????</p>
            <p>0 부터 시작가능</p>
          </td>
          <td>
            <e-input-number :isPhone="true" v-model="sample.num3" />
          </td>
          <td>
            <p>sample.num3</p>
            <p style='color:blue'>{{ sample.num3 }}</p>
          </td>
          <td>
            <input type="text" v-model="sample.tempNum3" style='width:100px'/>&nbsp;
            <button class="button gray sm" @click.prevent="apply('NUM3')">적용</button>
          </td>
        </tr>
        <tr>
          <td>
            <p>Element Attribute</p>
            <p>(name, id, class, readonly)</p>
          </td>
          <td>
            <e-input-number name="temp_name" id="temp_id" inputClass="temp_class" :readonly="sample.readonly"/>
          </td>
          <td>
            <p>sample.num4</p>
            <p style='color:blue'>{{ sample.num4 }}</p>
          </td>
          <td>
            <input type="text" v-model="sample.tempNum4" style='width:100px'/>&nbsp;
            <button class="button gray sm" @click.prevent="apply('NUM4')">적용</button>
            <p>
              <button class="button gray sm" @click.prevent="fnReadonly()">readonly : {{ sample.readonly }}</button>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>숫자형 으로 리턴</p>
            <p></p>
          </td>
          <td>
            <e-input-number :point="3" :isMinus="true" :isComma="true" v-model="sample.num5" :isReturnNumber="true"
                name="temp_name" id="temp_id" inputClass="temp_class" />
          </td>
          <td>
            <p>sample.num5</p>
            <p style='color:blue'>{{ sample.num5 }}</p>
            <p style='color:red'>{{ typeof sample.num5 }}</p>
          </td>
          <td>
            <input type="text" v-model="sample.tempNum5" style='width:100px'/>&nbsp;
            <button class="button gray sm" @click.prevent="apply('NUM5')">적용</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EInputNumber from '@/components/common/EInputNumber'

export default {
  name: 'SampleInputNumber',
  components: {
    EInputNumber
  },
  data: function () {
    return {
      sample: {
        num1: '',
        num2: '',
        num3: '',
        num4: '',
        num5: 0,
        tempNum1: '',
        tempNum2: '',
        tempNum3: '',
        tempNum4: '',
        tempNum5: 0,
        readonly: true
      }
    }
  },
  methods: {
    apply (type) {
      if (type === 'NUM1') {
        this.sample.num1 = this.sample.tempNum1
      } else if (type === 'NUM2') {
        this.sample.num2 = this.sample.tempNum2
      } else if (type === 'NUM3') {
        this.sample.num3 = this.sample.tempNum3
      } else if (type === 'NUM4') {
        this.sample.num4 = this.sample.tempNum4
      } else if (type === 'NUM5') {
        this.sample.num5 = this.sample.tempNum5
      }
    },
    fnReadonly () {
      this.sample.readonly = !this.sample.readonly
    }
  }
}

</script>
